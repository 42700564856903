var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-container',{ref:"container"},[_c('v-card',{staticClass:"fill-height",attrs:{"elevation":"2","shaped":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-uppercase pl-4 mr-auto"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex justify-end ml-auto pr-0 pb-2"},[_c('o-button',{attrs:{"busy":_vm.busy,"color":"green","icon":"mdi-plus","type":"label-icon","label":"Add New"},on:{"on-click":function($event){return _vm.add()}}}),_c('o-button',{attrs:{"busy":_vm.busy,"color":"primary","icon":"mdi-sync","type":"label-icon","label":"Refresh"},on:{"on-click":function($event){return _vm.reset()}}})],1)]),_c('v-divider'),_c('o-drawer',{attrs:{"actions":[
        {
          key: 'on-item-save',
          label: 'Save',
          icon: 'mdi-content-save',
          color: 'success',
        },
      ],"title":`${_vm.action} Segment Map`,"visible":_vm.action !== '',"width":"50vw"},on:{"on-close":function($event){_vm.action = ''},"on-item-save":_vm.save}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Alternative ID"},model:{value:(_vm.model.alternative_id),callback:function ($$v) {_vm.$set(_vm.model, "alternative_id", $$v)},expression:"model.alternative_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Alternative Name"},model:{value:(_vm.model.alternative_name),callback:function ($$v) {_vm.$set(_vm.model, "alternative_name", $$v)},expression:"model.alternative_name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-combo-box',{attrs:{"busy":_vm.dropBusy,"label":"Account","items":_vm.accounts},on:{"on-change":function($event){return _vm.getAdvertisers()}},model:{value:(_vm.model.alternative_account_id),callback:function ($$v) {_vm.$set(_vm.model, "alternative_account_id", $$v)},expression:"model.alternative_account_id"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-combo-box',{attrs:{"busy":_vm.dropBusy,"label":"Advertisers","items":_vm.advertisers},model:{value:(_vm.model.alternative_advertiser_id),callback:function ($$v) {_vm.$set(_vm.model, "alternative_advertiser_id", $$v)},expression:"model.alternative_advertiser_id"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Data Type","items":[
                { id: 1, name: 'First Party' },
                { id: 2, name: 'Retargeting' },
                { id: 3, name: 'Third Party' },
              ],"is-required":true},model:{value:(_vm.model.data_type),callback:function ($$v) {_vm.$set(_vm.model, "data_type", $$v)},expression:"model.data_type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Data Provider","items":[
                { id: 270, name: 'Octillion' },
                { id: 123, name: 'Cadent' },
                { id: 124, name: 'Veraset' },
                { id: 298, name: 'Liveramp' },
              ],"is-required":true},model:{value:(_vm.model.data_provider),callback:function ($$v) {_vm.$set(_vm.model, "data_provider", $$v)},expression:"model.data_provider"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Data Segment ID"},model:{value:(_vm.model.data_segment_id),callback:function ($$v) {_vm.$set(_vm.model, "data_segment_id", $$v)},expression:"model.data_segment_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Data Segment Path","is-required":true},model:{value:(_vm.model.data_segment_path),callback:function ($$v) {_vm.$set(_vm.model, "data_segment_path", $$v)},expression:"model.data_segment_path"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-check-box',{staticStyle:{"display":"inline-block"},attrs:{"busy":_vm.busy,"label":"Sync"},model:{value:(_vm.model.sync),callback:function ($$v) {_vm.$set(_vm.model, "sync", $$v)},expression:"model.sync"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Sync Segment Key"},model:{value:(_vm.model.sync_segment_key),callback:function ($$v) {_vm.$set(_vm.model, "sync_segment_key", $$v)},expression:"model.sync_segment_key"}})],1)],1)],1)],1),_c('v-card-text',{staticClass:"text-primary"},[_c('o-table',{ref:"table",attrs:{"busy":_vm.busy,"has-paging":false,"has-sorting":true,"height":_vm.getHeight(391),"fixed-header":true,"type":"array","data":_vm.segment_mappings,"headers":[
          {
            text: 'ID',
            align: 'end',
            sortable: true,
            value: 'id',
            type: 'int',
            width: 100,
          },
          {
            text: 'ALTERNATIVE ID',
            align: 'start',
            sortable: true,
            value: 'alternative_id',
            type: 'text',
            width: 170,
          },
          {
            text: 'ALTERNATIVE NAME',
            align: 'start',
            sortable: true,
            value: 'alternative_name',
            type: 'text',
          },
          {
            text: 'DATA PROVIDER',
            align: 'center',
            sortable: true,
            value: 'data_provider',
            type: 'slot',
            width: 170,
          },
          {
            text: 'DATA SEGMENT ID',
            align: 'start',
            sortable: true,
            value: 'data_segment_id',
            type: 'text',
          },
          {
            text: 'DATA SEGMENT PATH',
            align: 'start',
            sortable: true,
            value: 'data_segment_path',
            type: 'text',
          },
          {
            text: 'DATA TYPE',
            align: 'start',
            sortable: true,
            value: 'data_type',
            type: 'slot',
            width: 130,
          },
          {
            text: 'ACCOUNT ID',
            align: 'center',
            sortable: true,
            value: 'alternative_account_id',
            type: 'int',
            width: 130,
          },
          {
            text: 'ADVERTISER ID',
            align: 'center',
            sortable: true,
            value: 'alternative_advertiser_id',
            type: 'int',
             width: 150,
          },
          {
            text: 'STATUS',
            align: 'center',
            sortable: true,
            value: 'status',
            type: 'slot',
          },
          {
            text: 'USER COUNTS',
            align: 'center',
            sortable: true,
            value: 'user_count',
            type: 'int',
            width: 150,
          },
          {
            text: 'SYNC',
            align: 'center',
            sortable: true,
            value: 'sync',
            type: 'boolean',
          },
          {
            text: 'SYNC SEGMENT KEY',
            align: 'start',
            sortable: true,
            value: 'sync_segment_key',
            type: 'text',
          },
          {
            value: 'action',
            type: 'action',
            sortable: false,
            actions: [
              {
                key: 'on-item-edit',
                label: 'Edit',
                icon: 'mdi-pencil-box-outline',
                color: 'success',
              },
              {
                key: 'on-item-delete',
                label: 'Delete',
                icon: 'mdi-trash-can-outline',
                color: 'danger',
              },
            ],
          },
        ]},on:{"on-item-edit":_vm.edit,"on-item-delete":_vm.remove},scopedSlots:_vm._u([{key:"slot",fn:function(row){return [(row.prop.key === 'data_provider')?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getProviderName(row.prop.item.data_provider))+" ("+_vm._s(row.prop.item.data_provider)+") ")]):_vm._e(),(row.prop.key === 'data_type')?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getTypeName(row.prop.item.data_type))+" ("+_vm._s(row.prop.item.data_type)+") ")]):_vm._e(),(row.prop.key === 'status')?_c('div',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":row.prop.item.status === 2
                  ? 'success'
                  : [
                    1,
                    3,
                  ].includes(row.prop.item.status)
                    ? 'orange darken-1'
                    : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(row.prop.item.status == 2 ? "mdi-check" : [ 1, 3, ].includes(row.prop.item.status) ? "mdi-wrench" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(_vm.getStatusName(row.prop.item.status))+" ("+_vm._s(row.prop.item.status)+") ")],1)],1):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }