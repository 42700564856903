<template>
  <o-container ref="container">
    <v-card
      elevation="2"
      shaped
      class="fill-height"
    >
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>
      </v-card-title>
      <v-divider />

      <o-drawer
        :actions="[
          {
            key: 'on-item-save',
            label: 'Save',
            icon: 'mdi-content-save',
            color: 'success',
          },
        ]"
        :title="`${action} Segment Map`"
        :visible="action !== ''"
        width="50vw"
        @on-close="action = ''"
        @on-item-save="save"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="6">
              <o-input
                v-model="model.alternative_id"
                :busy="busy"
                label="Alternative ID"
              />
            </v-col>
            <v-col cols="12">
              <o-input
                v-model="model.alternative_name"
                :busy="busy"
                label="Alternative Name"
              />
            </v-col>
            <v-col cols="6">
              <o-combo-box
                v-model="model.alternative_account_id"
                :busy="dropBusy"
                label="Account"
                :items="accounts"
                @on-change="getAdvertisers()"
              />
            </v-col>
            <v-col cols="6">
              <o-combo-box
                v-model="model.alternative_advertiser_id"
                :busy="dropBusy"
                label="Advertisers"
                :items="advertisers"
              />
            </v-col>
            <v-col cols="6">
              <o-combo-box
                v-model="model.data_type"
                :busy="busy"
                label="Data Type"
                :items="[
                  { id: 1, name: 'First Party' },
                  { id: 2, name: 'Retargeting' },
                  { id: 3, name: 'Third Party' },
                ]"
                :is-required="true"
              />
            </v-col>
            <v-col cols="6">
              <o-combo-box
                v-model="model.data_provider"
                :busy="busy"
                label="Data Provider"
                :items="[
                  { id: 270, name: 'Octillion' },
                  { id: 123, name: 'Cadent' },
                  { id: 124, name: 'Veraset' },
                  { id: 298, name: 'Liveramp' },
                ]"
                :is-required="true"
              />
            </v-col>
            <v-col cols="6">
              <o-input
                v-model="model.data_segment_id"
                :busy="busy"
                label="Data Segment ID"
              />
            </v-col>
            <v-col cols="12">
              <o-input
                v-model="model.data_segment_path"
                :busy="busy"
                label="Data Segment Path"
                :is-required="true"
              />
            </v-col>
            <v-col cols="6">
              <o-check-box
                v-model="model.sync"
                :busy="busy"
                label="Sync"
                style="display:inline-block"
              />
            </v-col>
            <v-col cols="6">
              <o-input
                v-model="model.sync_segment_key"
                :busy="busy"
                label="Sync Segment Key"
              />
            </v-col>
          </v-row>
        </v-form>
      </o-drawer>

      <v-card-text class="text-primary">
        <o-table
          ref="table"
          :busy="busy"
          :has-paging="false"
          :has-sorting="true"
          :height="getHeight(391)"
          :fixed-header="true"
          type="array"
          :data="segment_mappings"
          :headers="[
            {
              text: 'ID',
              align: 'end',
              sortable: true,
              value: 'id',
              type: 'int',
              width: 100,
            },
            {
              text: 'ALTERNATIVE ID',
              align: 'start',
              sortable: true,
              value: 'alternative_id',
              type: 'text',
              width: 170,
            },
            {
              text: 'ALTERNATIVE NAME',
              align: 'start',
              sortable: true,
              value: 'alternative_name',
              type: 'text',
            },
            {
              text: 'DATA PROVIDER',
              align: 'center',
              sortable: true,
              value: 'data_provider',
              type: 'slot',
              width: 170,
            },
            {
              text: 'DATA SEGMENT ID',
              align: 'start',
              sortable: true,
              value: 'data_segment_id',
              type: 'text',
            },
            {
              text: 'DATA SEGMENT PATH',
              align: 'start',
              sortable: true,
              value: 'data_segment_path',
              type: 'text',
            },
            {
              text: 'DATA TYPE',
              align: 'start',
              sortable: true,
              value: 'data_type',
              type: 'slot',
              width: 130,
            },
            {
              text: 'ACCOUNT ID',
              align: 'center',
              sortable: true,
              value: 'alternative_account_id',
              type: 'int',
              width: 130,
            },
            {
              text: 'ADVERTISER ID',
              align: 'center',
              sortable: true,
              value: 'alternative_advertiser_id',
              type: 'int',
               width: 150,
            },
            {
              text: 'STATUS',
              align: 'center',
              sortable: true,
              value: 'status',
              type: 'slot',
            },
            {
              text: 'USER COUNTS',
              align: 'center',
              sortable: true,
              value: 'user_count',
              type: 'int',
              width: 150,
            },
            {
              text: 'SYNC',
              align: 'center',
              sortable: true,
              value: 'sync',
              type: 'boolean',
            },
            {
              text: 'SYNC SEGMENT KEY',
              align: 'start',
              sortable: true,
              value: 'sync_segment_key',
              type: 'text',
            },
            {
              value: 'action',
              type: 'action',
              sortable: false,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            },
          ]"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'data_provider'"
              class="text-center"
            >
              {{ getProviderName(row.prop.item.data_provider) }} ({{
                row.prop.item.data_provider
              }})
            </div>
            <div
              v-if="row.prop.key === 'data_type'"
              class="text-center"
            >
              {{ getTypeName(row.prop.item.data_type) }} ({{
                row.prop.item.data_type
              }})
            </div>
            <div
              v-if="row.prop.key === 'status'"
              class="text-center"
            >
              <v-chip
                class=""
                :color="
                  row.prop.item.status === 2
                    ? 'success'
                    : [
                      1,
                      3,
                    ].includes(row.prop.item.status)
                      ? 'orange darken-1'
                      : 'danger'
                "
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left
                >
                  {{
                    row.prop.item.status == 2
                      ? "mdi-check"
                      : [
                        1,
                        3,
                      ].includes(row.prop.item.status)
                        ? "mdi-wrench"
                        : "mdi-close"
                  }}
                </v-icon>
                {{ getStatusName(row.prop.item.status) }} ({{
                  row.prop.item.status
                }})
              </v-chip>
            </div>
          </template>
        </o-table>
      </v-card-text>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "SegmentMap",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Segments Map",
  },
  data() {
    return {
      title: "Segments Map",
      busy: false,
      dropBusy: false,
      action: "",
      segment_mappings: [],
      accounts: [],
      advertisers: [],
      valid: false,
      baseModel: {
        alternative_id: 0,
        alternative_name: "",
        alternative_account_id: 2,
        alternative_advertiser_id: 0,
        data_provider: 270,
        data_segment_id: "",
        data_segment_path: "",
        data_type: 1,
        status: 1,
        data_info: {
          taxonomy: "",
          data: "",
        },
        user_count: 0,
        sync: false,
        sync_segment_key: "",
        created_at: null,
        updated_at: null,
      },
      originalModel: {},
      model: {},
      permissions: [],
      permissionData: [],
    };
  },
  async mounted() {
    this.busy = true;
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
    this.reset();
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  watch: {},
  methods: {
    async getAdvertisers() { 
      this.dropBusy = true;
      this.advertisers = await this.getList(
        ["advertiser_id as id, advertiser_name as name"],
         "advertisers",
        [`account_id = ${this.model.alternative_account_id}`],
        [],
        "name"
      );
      this.dropBusy = false;
    },
    async loadAdvertisers(account_id) { 
      this.dropBusy = true;
      this.advertisers = await this.getList(
        ["advertiser_id as id, advertiser_name as name"],
         "advertisers",
        [`account_id = ${account_id}`],
        [],
        "advertiser_id"
      );
      this.dropBusy = false;
    },
    getStatusName(status) {
      let status_name;
      switch (status) {
        default:
        case 1:
          status_name = 'New';
          break;
        case 2:
          status_name = 'Loading Taxonomy';
          break;
        case 3:
          status_name = 'Loading Data';
          break;
        case 4:
          status_name = 'Error';
          break;
      }
      return status_name;
    },
    getProviderName(provider) {
      let provider_name;
      switch (provider) {
        default:
        case 123:
          provider_name = 'Cadent';
          break;
        case 124:
          provider_name = 'Veraset';
          break;
        case 270:
          provider_name = 'Octillion';
          break;
        case 298:
          provider_name = 'Liveramp';
          break;
      }
      return provider_name;
    },
    getTypeName(type) {
      let type_name;
      switch (type) {
        default:
        case 1:
          type_name = 'First Party';
          break;
        case 2:
          type_name = 'Retargeting';
          break;
        case 3:
          type_name = 'Third Party';
          break;
      }
      return type_name;
    },
    add() {
      this.action = "add";
      this.model = this.baseModel;
    },
    edit(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      this.action = "edit";

      this.loadAdvertisers(this.model.alternative_account_id);
    },
    async remove(item) {
      this.originalModel = _.cloneDeep(item);
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.alternative_name} ${this.model.alternative_account_id} (${this.model.alternative_advertiser_id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.model.deleted_by = this.loggedInUser.id;
            await this.deleteData(
              `/rest/segment_mappings/${this.model.id}`,
              null
            )
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The record of ${this.model.alternative_name} ${this.model.alternative_account_id} (${this.model.alternative_advertiser_id}) was successfully deleted.
                `,
                  "red",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                errors.push(err.data.message);
                this.model = this.baseModel;
                this.originalModel = this.baseModel;
              });
          }
        });
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/segment_mappings";

      this.model.account_id = this.loggedInUser.account_id;
      this.model.platform_id = this.loggedInUser.platform_id;

      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully added ${response.data.alternative_id} (${response.data.data_segment_id})
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            console.error(err);
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        const model = _.cloneDeep(this.model);

        if (this.model.created_by < 1) {
          this.model.created_by = this.loggedInUser.id;
        }
        this.model.updated_by = this.loggedInUser.id;
        await this.putData(`${url}/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully updated ${model.alternative_id} (${model.data_segment_id}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
                Error updating item: ${errors.join(",")}
                `,
          "red",
          2000
        );
        return false;
      } else {
        this.reset();
      }
    },
    reset() {
      this.action = "";
      this.model = this.baseModel;
      this.originalModel = this.baseModel;
      this.busy = true;
      setTimeout(async () => {
        const segment_mappings_url = `/rest/segment_mappings?platform_id=1&account_id=${this.loggedInUser.account_id}`;

        this.getData(segment_mappings_url, null).then((response) => {
          const segment_mappings = response.data;
          this.segment_mappings = segment_mappings.sort((a, b) =>
            a.alternative_name > b.alternative_name ? 1 : -1
          );
        });

        this.busy = false;
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
