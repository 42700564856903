<template>
  <SegmentMap dialog-name="Segments Mapping" />
</template>
<script>
import SegmentMap from '@/views/app/manage/SegmentMap'

export default {
  name: 'SegmentMapWrapper',
  components: {
    SegmentMap
  }
}
</script>
